
































































import Vue from 'vue';
import Component from 'vue-class-component';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { Prop } from 'vue-property-decorator';

@Component({ components: { FontAwesomeComponent } })
export default class TimeZoneSelector extends Vue {
  @Prop({
    required: true,
    default: getTimeZones().find((tz: TimeZone) => tz.name === Intl.DateTimeFormat().resolvedOptions().timeZone),
  })
  private value!: TimeZone;

  private timezones = getTimeZones();

  private selectedTimezone: TimeZone | undefined = undefined;

  private showTimezoneSelector = false;

  // eslint-disable-next-line class-methods-use-this
  private formattedTimeZoneName(timezone: TimeZone): string {
    return `(UTC${timezone.currentTimeFormat.slice(0, 6)}) ${timezone.name}`;
  }

  private onSelect(t: TimeZone): void {
    this.showTimezoneSelector = false;
    this.$emit('on-select', t);
  }
}
